.border-none[data-v-509d1e06]:before {
  display: none;
}
.border-none[data-v-509d1e06]:after {
  display: none;
}
.flex-fix[data-v-509d1e06] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-509d1e06]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-509d1e06] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-509d1e06] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-509d1e06] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-509d1e06] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-509d1e06] {
  *zoom: 1;
}
.clear-fix[data-v-509d1e06]:before,
.clear-fix[data-v-509d1e06]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.order-list[data-v-509d1e06] {
  padding-top: 0.88rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.list-top[data-v-509d1e06] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 0.88rem;
  background: #fff;
  position: fixed;
  top: 0.88rem;
  z-index: 999;
}
.list-top .list-item[data-v-509d1e06] {
  line-height: 0.88rem;
  font-size: 0.28rem;
  color: #7d7d7d;
  text-align: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  position: relative;
}
.list-top .active[data-v-509d1e06] {
  font-size: 0.28rem;
  color: #333333;
  font-weight: bold;
}
.list-top .active[data-v-509d1e06]::after {
  content: "";
  position: absolute;
  width: 0.5rem;
  height: 2px;
  background: var(--main-color);
  bottom: 0;
  left: 50%;
  margin-left: -0.25rem;
}
.order-item[data-v-509d1e06] {
  padding: 0 0.3rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #fff;
  margin-top: 0.2rem;
}
.order-item .order-title[data-v-509d1e06] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: #333;
  padding: 0.2rem 0 0.2rem 0.2rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
}
.order-item .order-title .shop[data-v-509d1e06] {
  font-size: 0.28rem;
}
.order-item .order-title .state[data-v-509d1e06] {
  font-size: 0.24rem;
}
.order-item .order-title[data-v-509d1e06]::before {
  content: "";
  width: 2px;
  height: 0.2rem;
  background: var(--main-color);
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -0.1rem;
}
.order-item .order-title-noborder[data-v-509d1e06]::before {
  content: "";
  width: 0px;
  height: 0rem;
}
.order-item .order-money[data-v-509d1e06] {
  padding: 0.14rem 0 0.2rem 0;
  border-bottom: 1px solid #e1e1e1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  color: #333;
}
.order-item .order-money .all-num[data-v-509d1e06] {
  margin-right: 0.3rem;
  height: 0.34rem;
  line-height: 0.34rem;
}
.order-item .order-money .all-money[data-v-509d1e06] {
  height: 0.34rem;
  line-height: 0.34rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.fs-12[data-v-509d1e06] {
  font-size: 0.24rem;
}
.fs-16[data-v-509d1e06] {
  font-size: 0.32rem;
}
.fs-weight[data-v-509d1e06] {
  font-weight: bold;
}
