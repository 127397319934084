.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.order-list {
  padding-top: 0.88rem;
  box-sizing: border-box;
}
.list-top {
  display: flex;
  align-items: center;
  width: 100%;
  height: 0.88rem;
  background: #fff;
  position: fixed;
  top: 0.88rem;
  z-index: 999;
}
.list-top .list-item {
  line-height: 0.88rem;
  font-size: 0.28rem;
  color: #7d7d7d;
  text-align: center;
  flex: 1;
  position: relative;
}
.list-top .active {
  font-size: 0.28rem;
  color: #333333;
  font-weight: bold;
}
.list-top .active::after {
  content: "";
  position: absolute;
  width: 0.5rem;
  height: 2px;
  background: var(--main-color);
  bottom: 0;
  left: 50%;
  margin-left: -0.25rem;
}
.order-item {
  padding: 0 0.3rem;
  box-sizing: border-box;
  background: #fff;
  margin-top: 0.2rem;
}
.order-item .order-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #333;
  padding: 0.2rem 0 0.2rem 0.2rem;
  box-sizing: border-box;
  position: relative;
}
.order-item .order-title .shop {
  font-size: 0.28rem;
}
.order-item .order-title .state {
  font-size: 0.24rem;
}
.order-item .order-title::before {
  content: "";
  width: 2px;
  height: 0.2rem;
  background: var(--main-color);
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -0.1rem;
}
.order-item .order-title-noborder::before {
  content: "";
  width: 0px;
  height: 0rem;
}
.order-item .order-money {
  padding: 0.14rem 0 0.2rem 0;
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  color: #333;
}
.order-item .order-money .all-num {
  margin-right: 0.3rem;
  height: 0.34rem;
  line-height: 0.34rem;
}
.order-item .order-money .all-money {
  height: 0.34rem;
  line-height: 0.34rem;
  display: flex;
  align-items: center;
}
.fs-12 {
  font-size: 0.24rem;
}
.fs-16 {
  font-size: 0.32rem;
}
.fs-weight {
  font-weight: bold;
}
